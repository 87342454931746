import React from "react";
import Layout from "../components/Layout";
import HeaderSimple from "../components/HeaderSimple";

const Privacy = ({ pageContext }) => {
    const scrollTo = (section) => {
        window.scrollTo({ 
            top: window.document.querySelector(section).offsetTop, 
            behavior: 'smooth'
        });
    }

    return (
        <Layout pageContext={pageContext}>
            <HeaderSimple subtitle={`Privacy Policy`} title={`Regolamento UE 679/2016 Regolamento generale sulla protezione dei dati`} />
            
            <div className="container">
                <p>
                    Con la presente policy Gruppo Pam S.p.A. intende informare gli utenti visitatori 
                    del sito web <a style={{color: "#A19E94", textDecoration: "underline"}} href="http://www.gruppopam.it">www.gruppopam.it</a> (di seguito, il <strong>"Sito"</strong>) della politica adottata 
                    in materia di Protezione dei dati personali, sottolineando il proprio impegno 
                    e la propria attenzione con riferimento alla tutela della privacy dei visitatori del Sito. 
                    La preghiamo di leggere attentamente la nostra Privacy Policy, che si applica 
                    anche nel caso Lei acceda al Sito e decida semplicemente di navigare al suo interno.<br/>
                    La navigazione all'interno del Sito è libera e non richiede registrazione alcuna, 
                    con eccezione di alcune aree in cui l'utente può liberamente ed espressamente fornire 
                    i propri dati personali (es. per fornire il proprio curriculum vitae nell’area “Lavora con noi”). 
                    Laddove l’utente intenda fornire i propri dati personali, egli sarà espressamente informato 
                    ai sensi del Regolamento UE 679/2016 Regolamento generale sulla protezione dei dati 
                    (il <strong>“Regolamento”</strong>) con indicazione (a titolo esemplificativo) delle finalità e 
                    modalità di utilizzo dei dati, nonché del diritto di richiedere in ogni momento 
                    la loro cancellazione o il loro aggiornamento.<br/>
                    Ai sensi e per gli effetti dell’art. 13 del Regolamento UE 679/2016 Regolamento 
                    generale sulla protezione dei dati, Gruppo Pam S.p.A. Le fornisce le seguenti informazioni.
                </p><br/>

                <h5 className="subtitle gray">1. Identità e dati di contatto del Titolare del trattamento</h5>
                <p>
                    Il sito web <a style={{color: "#A19E94", textDecoration: "underline"}} href="http://www.gruppopam.it">www.gruppopam.it</a> (il <strong>"Sito"</strong>) è gestito da Gruppo PAM S.p.A., con sede legale in 
                    Spinea, Via del Commercio, 27 e sedi operative in Spinea (VE), Via delle Industrie e Via del Commercio, tel. 041 5495111, 
                    Partita Iva n. 01534730278, Codice Fiscale e numero di Iscrizione al Registro Imprese di 
                    Venezia Rovigo n. 01166060267  (<strong>“Gruppo Pam”</strong> o la <strong>“Società”</strong>), in qualità di Titolare del 
                    trattamento ai sensi del Regolamento.
                </p><br/>

                <h5 className="subtitle gray">2. Tipi di dati personali trattati</h5>
                <p>
                    Per accedere al Sito non è necessario procedere ad alcuna registrazione. 
                    Esistono, tuttavia, all'interno del Sito, delle sezioni che richiedono una 
                    registrazione o l’utilizzo di username e password (ad es. per rilasciare il 
                    proprio curriculum vite, ecc.). 
                    Clicchi qui per <a style={{color: "#A19E94", textDecoration: "underline"}} onClick={() => scrollTo('#link-1')}>maggiori informazioni [LINK 1]</a>.<br/>
                    Con riferimento ai dati relativi alla navigazione all'interno del Sito, 
                    si veda anche il successivo punto 7, riguardante gli Strumenti di profilazione utilizzati dal Sito. 
                </p><br/>
        
                <h5 className="subtitle gray">3. Finalità e base giuridica del trattamento</h5>
                <p>
                    I dati sono raccolti e trattati per finalità strettamente connesse all'uso del Sito e dei suoi servizi. Le finalità di utilizzo dei dati sono indicate in modo dettagliato nelle specifiche informative fornite dal Sito in tutti i casi di raccolta dei dati. Le chiediamo, quindi, di voler leggere le informative che, di volta in volta, illustrano le caratteristiche dei trattamenti che saranno svolti da Gruppo Pam (ad es. per candidarsi ad una posizione lavorativa aperta). <br/>
                    Il trattamento dei dati avverrà nel pieno rispetto della <a style={{color: "#A19E94", textDecoration: "underline"}} onClick={() => scrollTo('#link-2')}>normativa privacy [LINK 2]</a>. 
                </p><br/>

                <h5 className="subtitle gray">4. Facoltatività del conferimento dei dati personali</h5>
                <p>
                    Il conferimento dei dati personali ha, in linea generale, natura facoltativa. Solo in determinati casi il mancato conferimento dei dati può comportare l'impossibilità di accedere a servizi specifici ed ottenere quanto eventualmente richiesto (ad es. per presentare la propria candidatura); il mancato conferimento di tali dati può quindi impedire a Gruppo Pam di consentire l’accesso ai servizi del Sito o di rispondere alle richieste degli utenti.<br/>
                    I dati di volta in volta necessari sono indicati nei moduli di raccolta dati presenti nel Sito - es. indicandoli con un asterisco (*) - e le conseguenze del loro mancato conferimento sono riportate nelle specifiche informative presenti nelle pagine di raccolta dei dati.
                </p><br/>

                <h5 className="subtitle gray">5. Modalità del trattamento dei dati ed ambito di comunicazione</h5>
                <p>
                    I dati saranno trattati su supporto elettronico. Gruppo Pam assicura il trattamento lecito e secondo correttezza dei dati personali forniti attraverso il Sito, nel pieno rispetto della normativa vigente, nonché la massima riservatezza dei dati forniti in sede di registrazione. <br/>
                    I dati non saranno diffusi e saranno trattati da personale espressamente autorizzato alla gestione del Sito della Direzione Marketing Strategico. <br/>
                    Per esigenze esclusivamente organizzative e funzionali, abbiamo nominato alcuni fornitori di servizi funzionali alla gestione del Sito quali responsabili esterni del trattamento dei dati personali degli utenti per finalità strettamente connesse e correlate alla prestazione dei servizi forniti, fra cui Pam Panorama S.p.A., con sede in via del Commercio n. 27, Spinea (VE), società nostra controllata e responsabile della gestione dei nostri sistemi informativi. Un elenco completo dei responsabili esterni del trattamento può essere richiesto contattando <a style={{color: "#A19E94", textDecoration: "underline"}} href="mailto:servizioclienti@pampanorama.it">servizioclienti@pampanorama.it</a>. 
                </p><br/>

                <h5 className="subtitle gray">6. Collegamenti ad altri siti</h5>
                <p>
                    La presente informativa è fornita solo per il Sito e non anche per altri siti web eventualmente consultati dall'utente tramite link di collegamento. Gruppo Pam non può essere ritenuta responsabile dei dati personali forniti dagli utenti a soggetti esterni o a eventuali siti web collegati al Sito. 
                </p><br/>
                 
                <h5 className="subtitle gray">7. Strumenti di "profilazione" e/o personalizzazione</h5>
                <p>
                    Gruppo Pam non svolge alcuna attività di comunicazione promozionale e/o pubblicitaria.  <br/>
                    Il Sito utilizza unicamente “cookie” tecnici (finalizzati a facilitare la navigazione e l’utilizzo del Sito) od analitici, con oscuramento dell’indirizzo IP (cioè cookie funzionali ad analizzare in modo aggregato e non identificativo l’utilizzo del Sito).<br/>
                    Per una spiegazione dettagliata sui cookies utilizzati dal Sito e su come disattivarli, La invitiamo a leggere la nostra Cookie Policy <a style={{color: "#A19E94", textDecoration: "underline"}} onClick={() => scrollTo('#link-3')}>[LINK 3]</a>.  
                </p><br/>
                 
                <h5 className="subtitle gray">8. Luogo di trattamento dei dati e trasferimenti fuori dall’Unione Europea</h5>
                <p>
                    I trattamenti connessi ai servizi forniti dal Sito sono svolti presso la sede amministrativa di Pam Panorama S.p.A., via del Commercio n. 27, Spinea (VE).
                    I dati raccolti e trattati tramite il Sito non saranno oggetto di trasferimento verso Paesi non appartenenti all’Unione Europea.
                </p><br/>

                <h5 className="subtitle gray">9. Periodo di conservazione dei dati</h5>
                <p>
                    I Suoi dati saranno trattati per i tempi previsti nelle specifiche informative fornite al momento della raccolta (ad es. con riferimento ai dati del suo curriculum vitae, si invita ad esaminare l’informativa presente nell’area “Lavora con noi”.). <br/>
                    Per quanto riguarda ai tempi di conservazione dei dati rilevati mediante l’utilizzo di strumenti di profilazione (es. cookie), si veda la nostra Cookie Policy <a style={{color: "#A19E94", textDecoration: "underline"}} onClick={() => scrollTo('#link-3')}>[LINK 3]</a>.
                </p><br/>

                <h5 className="subtitle gray">10. Diritti degli interessati</h5>
                <p>
                    Mediante comunicazione da inviarsi a Gruppo Pam S.p.A., con sede operativa in Spinea (VE), Via del Commercio n.27, e-mail: <a style={{color: "#A19E94", textDecoration: "underline"}} href="mailto:servizioclienti@pampanorama.it">servizioclienti@pampanorama.it</a>, Lei potrà in ogni momento esercitare i diritti <a style={{color: "#A19E94", textDecoration: "underline"}} onClick={() => scrollTo('#link-4')}>[LINK 4]</a> di cui agli artt. da 15 a 22 del Regolamento, tra cui conoscere quali dati stiamo trattando, con quali modalità e per quali finalità li utilizziamo, modificare i dati che ci ha fornito o cancellarli, chiederci di limitare l’uso dei Suoi dati, richiedere di ricevere o trasmettere i Suoi dati, ferma restando sempre la possibilità di cambiare i Suoi consensi (eventualmente forniti). Può, inoltre, sempre opporsi al trattamento dei Suoi dati effettuato, in particolare, per finalità di marketing o analisi delle Sue preferenze.
                </p><br/>

                <h5 className="subtitle gray">11. A chi può rivolgersi per proporre un reclamo</h5>
                <p>
                    Le ricordiamo che, qualora ritenga che il trattamento che la riguarda violi le disposizioni di cui al Regolamento, può sempre proporre reclamo all’autorità Garante per la protezione dei dati personali (<a style={{color: "#A19E94", textDecoration: "underline"}} href="http://www.garanteprivacy.it">www.garanteprivacy.it</a>) oppure all’autorità Garante del Paese in cui risiede abitualmente, lavora oppure del luogo ove si è verificata la presunta violazione.
                </p><br/>

                <h5 className="subtitle gray">12. Il Responsabile della Protezione dei dati</h5>
                <p>
                    Il Responsabile della protezione dei dati designato ai sensi dell’art. 37 del Regolamento da Gruppo Pam è contattabile all’indirizzo di posta responsabileprotezionedati@gruppopam.it. 
                </p><br/>

                <h5 className="subtitle gray">13.  Legge applicabile</h5>
                <p>
                    Questa Privacy Policy è disciplinata dal Regolamento UE 679/2016, che garantisce che il trattamento dei dati personali si svolga nel rispetto dei diritti e delle libertà fondamentali, nonché della dignità dell'interessato, con particolare riferimento alla riservatezza, all'identità personale e al diritto alla protezione dei dati personali.
                </p><br/>

                <h5 className="subtitle gray">14. Clausola di revisione</h5>
                <p>
                    Gruppo Pam si riserva di rivedere, modificare o semplicemente aggiornare, in tutto o in parte, a propria esclusiva discrezione, in qualsiasi modo e/o in qualsiasi momento, senza preavviso, la presente Privacy Policy, anche in considerazione di modifiche di norme di legge o di regolamento in materia di protezione dei dati personali. Le modifiche e gli aggiornamenti della Privacy Policy saranno notificati agli utenti mediante pubblicazione nella Home Page del Sito e saranno vincolanti non appena pubblicati. La preghiamo, pertanto, di accedere con regolarità a questa sezione per verificare la pubblicazione della più recente ed aggiornata Privacy Policy.
                </p><br/>

                <p>Il testo integrale del Regolamento UE 679/2016 è consultabile sul sito del Garante per la Protezione dei dati personali <a style={{color: "#A19E94", textDecoration: "underline"}} href="http://www.garanteprivacy.it">www.garanteprivacy.it</a>.</p>
            </div>

            <div className="container" style={{marginTop: "80px"}}>
                <h5 className="subtitle">Si riportano di seguito i LINK richiamati nel testo della Privacy Policy:</h5>
                <br/>

                <h5 className="subtitle gray">[LINK 1]</h5>
                <p id="link-1">
                    Qualora Gruppo Pam acquisisca informazioni e dati personali informerà preventivamente, 
                    di volta in volta, gli utenti delle finalità per cui tali dati sono richiesti e delle 
                    modalità con cui saranno utilizzati. Ove necessario, Gruppo Pam provvederà ad acquisire 
                    il consenso specifico dell'utente al relativo utilizzo dei dati. A titolo esemplificativo, 
                    saranno richiesti i dati personali necessari a valutare il curriculum vitae di un candidato 
                    ad una posizione lavorativa aperta nell’area “Lavora con noi”. Gruppo Pam utilizzerà i dati 
                    personali raccolti on-line ai soli fini indicati in sede di registrazione. <br/>
                    I dati relativi alla connessione e navigazione all'interno del Sito (quali gli indirizzi 
                    in notazione URI-Uniform Resource Identifier delle risorse richieste, l'orario della 
                    richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione 
                    del file ottenuto in risposta, il codice numerico indicante lo stato della risposta 
                    data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema 
                    operativo e all'ambiente informatico dell'utente) sono raccolti al solo fine di 
                    ricavare informazioni statistiche anonime sull'uso del Sito e per controllarne 
                    il corretto funzionamento e vengono cancellati immediatamente dopo l'elaborazione. 
                    I dati potrebbero essere utilizzati per l'accertamento di responsabilità in caso 
                    di ipotetici reati informatici ai danni del Sito: con esclusione di tale eventualità, 
                    iI dati saranno cancellati decorso il tempo utile per l'erogazione dei servizi illustrati nel Sito. 
                </p><br/>

                <h5 className="subtitle gray">[LINK 2]</h5>
                <p id="link-2">
                    Il trattamento dei Suoi dati avverrà:<br/>
                    - ai sensi dell’art. 6, comma 1, lett. b) del Regolamento per gestire la fruizione dei Servizi richiesti tramite il Sito;<br/>
                    - sulla base del Suo consenso, ai sensi dell’art. 6, comma 1, lett. a) del Regolamento, per presentare la propria con candidatura.
                </p><br/>
                
                <h5 className="subtitle gray">[LINK 4]</h5>
                <p id="link-4">
                    Gli artt. da 15 a 22 del Regolamento riconoscono i seguenti diritti: 
                    <ul>
                        <li>
                            Diritto di accesso: l’interessato può richiedere quali dati che lo riguardano vengono trattati, le finalità del trattamento, le categorie di dati trattati, i destinatari a cui i dati personali sono stati o saranno comunicati, l’eventuale trasferimento dei dati al di fuori dell’Unione Europea, il periodo di conservazione dei dati, l’esistenza di un processo decisionale automatizzato (compresa la profilazione), nonché l’origine dei dati se non sono raccolti presso l’Interessato stesso (art. 15 del Regolamento).
                        </li>
                        <li>
                            Diritto di rettifica: l’interessato può chiedere la correzione o aggiornamento dei dati inesatti ovvero, tenuto conto della finalità del trattamento, l'integrazione dei dati personali incompleti (art. 16 del Regolamento).
                        </li>
                        <li>
                            Diritto di cancellazione (oblio): (ove applicabile) l’interessato ha diritto ad ottenere la cancellazione dei dati personali che lo riguardano, ad esempio quando i dati personali non sono più necessari per lo scopo per cui sono stati raccolti. Non sarà peraltro possibile richiedere la cancellazione dei dati se sono necessari ad adempiere ad un obbligo di legge (es. tenuta delle scritture contabili, sicurezza, ecc.) oppure per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria (art. 17 del Regolamento).
                        </li>
                        <li>
                            Diritto di limitazione: l’interessato può chiedere che il trattamento dei dati sia limitato, ad esempio quando si contesta l'esattezza dei dati personali, per il periodo necessario per verificare l'esattezza di tali dati (art. 18 del Regolamento).
                        </li>
                        <li>
                            Diritto di notifica: l’interessato ha diritto ad essere informato delle eventuali rettifiche o cancellazioni o limitazioni del trattamento effettuate in relazione ai dati personali che lo riguardano, e dei destinatari a cui siano state comunicate (art. 19 del Regolamento).
                        </li>
                        <li>
                            Diritto di portabilità: (ove applicabile) l’interessato ha diritto di ricevere i dati che lo riguardano in un formato strutturato, di uso comune e leggibile da dispositivo automatico (es. in formato .excel, .csv). Tale diritto è tuttavia limitato ai soli dati trattati con mezzi automatizzati e sulla base di un contratto con l’interessato, o del suo consenso. Se tecnicamente fattibile, sarà possibile richiedere la trasmissione dei dati direttamente ad altro titolare del trattamento (art. 220 del Regolamento).
                        </li>
                        <li>
                            Diritto di opposizione: (ove applicabile) l’interessato ha il diritto di opporsi in qualsiasi momento, per motivi connessi alla sua situazione particolare, al trattamento dei dati personali che la riguardano svolto, ad esempio, per il perseguimento di un legittimo interesse del titolare del trattamento o di terzi (ivi inclusa la profilazione). Può inoltre sempre opporsi al trattamento dei dati effettuato per finalità di marketing diretto, compresa la profilazione connessa a tale marketing diretto (art. 21 del Regolamento).
                        </li>
                        <li>
                            Diritto di non essere sottoposto a decisioni automatizzate, compresa la profilazione: (ove applicabile) l'interessato ha il diritto di non essere sottoposto a una decisione basata unicamente su un trattamento automatizzato, compresa la profilazione, che produca effetti giuridici che lo riguardano o che incida significativamente sulla sua persona, salvo nei casi in cui la decisione automatizzata sia necessaria (i) per la conclusione o l'esecuzione di un contratto tra l'interessato e un titolare del trattamento, (ii) sia prevista dalla legge, nel rispetto di misure e cautele, (iii) si basi sul consenso esplicito dell'interessato (art. 22 del Regolamento).
                        </li>
                    </ul>
                </p>

                <h5 className="subtitle gray">[LINK 3]</h5>
                <p id="link-3">
                    <strong>Cosa sono i cookie</strong><br/>
                    I cookie sono file di testo che vengono inviati da un server web (cioè il computer in cui viene “ospitato” il sito web visitato) al browser dell’utente (Internet Explorer, Firefox, Google Chrome, ecc.) e memorizzati nel dispositivo, fisso o mobile (computer, smartphone, tablet), utilizzato per la navigazione dell'utente. Il cookie consente al Sito di identificare il dispositivo dell'utente, attraverso le informazioni in esso memorizzate, ogni qualvolta il medesimo si ricollega al Sito attraverso quel dispositivo. 
                    Attraverso l'utilizzo dei cookie ci è possibile rendere più semplice la navigazione dell'utente all'interno del Sito, nonché analizzarne le preferenze ed i gusti attraverso la verifica della navigazione, e conseguentemente offrire prodotti e servizi più coerenti rispetto agli interessi ed alle aspettative dell’utente stesso. <br/>
                    I cookie possono essere "di sessione" (quando vengono memorizzati esclusivamente per il tempo di durata della sessione di navigazione nel sito e sono cancellati con la chiusura del browser), oppure “permanenti” (vengono memorizzati per un tempo maggiore, fino alla loro scadenza o sino alla cancellazione da parte dell’utente). Inoltre, i cookie possono essere installati dal sito che l’utente sta visitando (c.d. cookie di prima parte) o possono essere installati da altri siti web (c.d. cookie di terze parti).
                    I cookies non raccolgono informazioni direttamente identificative dell'utente. Infatti, Gruppo Pam non può attraverso i cookies risalire a nessuna informazione personale direttamente identificativa (es. nome, cognome) se non fornita direttamente dall’utente. 
                    <br/><br/>
                     
                    <strong>Quali tipi di cookie utilizziamo</strong><br/>
                    <i>Cookie tecnici di navigazione o di sessione </i><br/>
                    Gruppo Pam utilizza cookie tecnici di navigazione o di sessione per consentirle una normale ed efficiente navigazione e fruizione del Sito.
                    Tali cookie non richiedono l’acquisizione di un consenso preventivo ed espresso dell’utente in quanto strettamente necessari ad erogare il servizio richiesto dall’utente del Sito.  
                    <br/>
                    <i>Cookie tecnici di funzionalità</i><br/>
                    Gruppo Pam utilizza cookie tecnici di funzionalità che Le permettono la navigazione in funzione dei criteri da Lei selezionati (ad esempio, la lingua scelta) al fine di migliorare i servizi resi dal Sito. Nel Sito, tali cookie permettono di essere riconosciuto negli accessi successivi. I cookie funzionali non sono indispensabili al funzionamento del Sito, ma migliorano la qualità e l'esperienza della navigazione.
                    Tali cookie non richiedono l’acquisizione di un consenso preventivo ed espresso dell’utente in quanto strettamente necessari ad erogare il servizio richiesto dall’utente del Sito. 
                    <br/>
                    <i>Cookie analitici</i><br/>
                    Gruppo Pam, quale gestore del Sito, utilizza cookie analitici di terze parti per raccogliere informazioni sull’utilizzo del Sito da parte degli utenti (numero di visitatori, pagine visitate, tempo di permanenza sul sito ecc.) per elaborare analisi statistiche, su base aggregata, sul numero degli utenti e su come questi navigano sul nostro Sito. Per l’utilizzo di tali cookie sono stati adottati i criteri per il mascheramento ad origine dell’indirizzo IP.
                    Tali cookie non richiedono l’acquisizione di un consenso preventivo ed espresso dell’utente in quanto strettamente necessari ad erogare il servizio richiesto dall’utente del Sito. 
                    <br/><br/>
                    
                    <strong>Caratteristiche dei cookie utilizzati dal Sito</strong><br/>
                    Le caratteristiche dei cookie utilizzati sul Sito sono illustrate nella tabella che segue.<br/>

                    <table>
                        <thead>
                            <tr>
                                <th>Cookie</th>
                                <th>Finalità</th>
                                <th>Tipologia e impostazione</th>
                                <th>Durata</th>
                                <th>Consenso dell’utente</th>
                                <th>Ulteriori informazioni e link alle informative ed ai moduli di consenso di terzi parti</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Analytcs della piattaforma di Google Analytics</td>
                                <td>Raccoglie informazioni aggregate sulle pagine visitate dagli utenti e su eventuali altre azioni che gli stessi possono fare su queste pagine. Non rileva dati personali relativi all’utente, ma solo dati in forma anonima. La raccolta di questi dati è volta a migliorare l’esperienza di navigazione degli utenti.</td>
                                <td>
                                    3° parte
                                    Impostazioni:
                                    è stato anonimizzato l’indirizzo IP (in questo modo viene mascherato ad origine l’indirizzo IP) e pertanto è inibita ad origine la possibilità di accedere ai dati disaggregati; è stato impostato l’account in modo da non condividere dati con Google; è stato disattivato il servizio pubblicitario di Google Analytics.
                                </td>
                                <td>Persistente</td>
                                <td>Non richiesto</td>
                                <td>
                                    PER GOOGLE:
                                    La privacy policy di Google è disponibile alla seguente url: <a href="https://policies.google.com/privacy?hl=it-IT">policies.google.com/privacy</a>
                                    Può disabilitare i cookie di Google Analytics scaricando uno specifico plug-in del browser reperibile alla seguente url: https://tools.google.com/dlpage/gaoptout
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <br/><br/>
                    
                    <strong>Come modificare le impostazioni browser sui cookie</strong><br/>

                    È possibile disattivare/attivare o cancellare i cookie in ogni momento mediante utilizzo delle impostazioni del Suo web browser. In particolare, se si desidera non ricevere cookie, è possibile impostare il proprio browser in modo tale da essere avvertiti della presenza di un cookie potendo così decidere se accettarlo o meno; si possono anche rifiutare automaticamente tutti i cookie, attivando l'apposita opzione nel browser. È anche possibile eliminare cookie specifici che sono già stati memorizzati all'interno del browser, o bloccare la memorizzazione di cookie sul vostro computer da parte di siti web specifici, oppure bloccare i cookie di terze parti.<br/>
                    Di seguito riportiamo le modalità offerte dai principali browser:
                    <ul>
                        <li><a style={{color: "#A19E94", textDecoration: "underline"}} href="http://windows.microsoft.com/it-it/windows7/how-to-manage-cookie-in-internet-explorer-9">Internet Explorer</a></li>
                        <li><a style={{color: "#A19E94", textDecoration: "underline"}} href="https://support.google.com/chrome/answer/95647?hl=it">Chrome</a></li>
                        <li><a style={{color: "#A19E94", textDecoration: "underline"}} href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">Firefox</a></li>
                        <li><a style={{color: "#A19E94", textDecoration: "underline"}} href="http://help.opera.com/Windows/10.00/it/cookie.html">Opera</a></li>
                        <li><a style={{color: "#A19E94", textDecoration: "underline"}} href="https://support.apple.com/it-it/HT201265">Safari</a></li>
                    </ul>
                    Dopo la disattivazione di tutti i cookie alcune funzioni delle pagine web potrebbero non essere eseguite correttamente.<br/>
                    È inoltre possibile gestire le Sue preferenze in materia di cookie su <a style={{color: "#A19E94", textDecoration: "underline"}} href="http://www.youronlinechoices.com">www.youronlinechoices.com</a>.
                    <br/><br/>

                    <strong>Trasferimento dei Suoi dati fuori dell’Unione Europea</strong><br/>
                    I dati raccolti non sono trasferiti fuori dall’Unione Europea.

                    <br/><br/>
                    Per ulteriori informazioni sui suoi diritti e come contattarci, torni alla Privacy Policy.
                </p>
            </div>
        </Layout>
    )
}

export default Privacy